import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'
 export const Nav = styled.nav
 `
 background: ${({ scrollNav }) => (scrollNav ? 'red' :'transparent')};
 height: 80px;
// margin-top: -40px;
//  display: flex;
//  justify-content: center;
//   align-items: center;
 font-size: 1rem;
 position: sticky;
 top: 10;
 z-index:10;

 @media screen and (max-width: 960px){
     transition: 0.8s all ease;
 }
 
 `;
 export const NavbarContainer = styled.div`
 display: flex;
//  justify-content: space-between;
 height: 80px;
 width: 1100px;
 z-index: 1;
 width: 100%
 padding-right: 0 34px;
 margin-right: 0px;
 max-width: 1100px;

 @media screen and (max-width: 768px){
    width:768px;
 }
 @media screen and (max-width: 482px){
     width:480px;
 }
 `;
 export const NavLogo= styled(LinkR)
 `
 color: #fff;
//  justify-self: flex-start;
 cursor: pointer;
 font-size: 1.5rem;
 display: flex;
 align-items: center;
 margin-left: 24px;
 font-weight: bold;
 text-decoration: none;
 height:30px;
 width:30px;
 `;
 
 export const  MobileIcon = styled.div
 `
 display: none;
 @media screen and (max-width: 768px){
     display: block;
     position: absolute;
     top: 10;
     right: 0;
     transform: translated(-100%, 60%);
     font-size: 1.8rem;
     cursor: pointer;
     color: #000;
     padding-top: 20px;
     padding-right: 10px;
 }
 @media screen and (max-width: 482px){
    display: block;
    position: absolute;
    top: 10;
    right: 0;
    transform: translated(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000;
    padding-top: 20px;
    padding-right: 10px;
}
 `
 export const NavMenu = styled.ul`
display: flex;
align-items: right;
list-style: none;
text-align: center;
// margin-left: 10px;
padding-left: 25px;

// margin-right: -422px;



@media screen and (max-width: 768px){
    display: none;
}
 `;
 export const NavItem = styled.li
 `
//  padding-left: 65px;
 padding-top:35px;
 height: 80px;
 `;
 export const NavLinks = styled(LinkS)`
  color: #00B74A ;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%
  cursor: pointer; 
  font-weight: bold;
  
 
&.active {
    border-bottom: 3px solid #01bf71;
}
`;
export const NavBtn = styled.nav`
margin-right: 3px;
// padding-left: 400px;
// align-items: center;
padding-top: 15px;
// padding-right:0px;
// justify-content: flex-start;



@media screen and(max-width: 768px){
    display: none;
}
`
export const NavBtnLink = styled(LinkR)`
border-radius : 50px;
background: #00B74A;
white-space: nowrap;
padding: 10px 22px;
color: #fff;
font-size: 16px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
    transition: all 0.2s ease-in-out;
    background: #949494;
    color: #fff;
}

`



