import styled from 'styled-components';
import {MdClose} from 'react-icons/md';
export const Container = styled.div
`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;

`
export const Background = styled.div
`
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.8);
position:fixed;
display: flex;
justify-content: center;
align-items: center;

`
export const ModalWrapper = styled.div 
`
width: 700px;
height: 500px;
box-shadow: 0 5px 16px rgba(0,0,0, 0.2);
background: #fff;
color: #000;
display: grid;
grid-templat-columns: 1fr, 1fr;
position: relative;
z-index: 10px;
border-radius:10px;


@media screen and (max-width: 768px){
 width:500px;
 height:500px;

}

@media screen and (max-width: 460px){
  width:380px;
  height:500px;
 
 }
`
export const ModalImg = styled.img
`
width: 100%;
height: 100%;
border-radius: 10px 0 0 10px;
background: #000;
`

export const ModalContent = styled.div
`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
line-height: 1.8;
color: #00B74A;
margin-bottom: 20px;
margin-left:15px;
margin-right:15px;

p{
    margin-bottom: 1 rem;
}

button {
    padding: 10px 24px;
    background: #00B74A;
    color: #fff;
    border: none;
}
`;

export const Heading = styled.div
`
font-size: 50px;

@media screen and (max-width: 460px){
  font-size:30px;
 
 }
`

export const CloseModalButton = styled(MdClose)
`
cursor: pointer;
position: absolute; 
top: 28px;
right: 20px;
width: 32px;
height: 32px;
padding: 0;
z-index: 10;

@media screen and (max-width: 460px){
 cursor:pointer;
position: absolute; 
top: 10px;
right: 15px;
width: 26px;
height: 26px;
padding: 0;
z-index: 10;
 
 }

 @media screen and (max-width: 760px){
  cursor:pointer;
 position: absolute; 
 top: 10px;
 right: 15px;
 width: 26px;
 height: 26px;
 padding: 0;
 z-index: 10;
  
  }
`;

export const Button = styled.button`

min-width: 150px;
padding: 10px 10px;
border-radius: 4px;
border: none;
background: #00B74A;
color: #fff;
font-size: 14px;
cursor: pointer;
margin-bottom:490px;
margin-left: 940px;
radius:10px;

@media screen and (max-width: 1000px){
  margin-left: 700px;
  min-width:150px;
  font-size: 14px;

}

@media screen and (max-width: 868px){
  margin-left: 600px;
  min-width:200px;
  font-size: 15px;
}

@media screen and (max-width: 768px){
  margin-left: 500px;
  min-width:150px;
  font-size: 14px;
}

@media screen and (max-width: 640px){
  margin-left: 300px;
  min-width:150px;
  font-size: 14px;
}

@media screen and (max-width: 460px){
  margin-left: 200px;
  min-width:150px;
  font-size: 14px;
}
`
