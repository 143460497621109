import React, {Component, useState}from 'react'
import { BlogContainer, BlogWrapper, ContentA,BH2,BP, Heading, Img, ImgWrap, TextWrapper,
} from '../BlogElements'
import benifits from '../../../images/benifits.png'
import Image from 'react-bootstrap/Image';
import {Col, Button} from 'react-bootstrap';
import ToggleDisplay from 'react-toggle-display';

  export default class CircularEconomy extends Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.state = { show: false };
    }
   
    handleClick() {
      this.setState({
        show: !this.state.show
      });
    }

    render(){
      const { show } = this.state;
      let title = this.state.show? "Show less": "Read more";
        return(
        <>
        <BH2>The Green Road Way : Circular Economy Model for Sustainability of Earth.</BH2>

        Most of the wastes produced are not managed properly. The idea of a circular 
economy is a model where we try to find value-based wastes and focus on resource 
recovery of wastes. The main ideology of circular economy usually rests around waste 
segregation and auditing which could help to find and separate the wastes that are 
produced, decreases the chance of mixing of different kind of wastes so that the 
waste resources can be recovered in the most value optimized way possible. This 
further expands the possibilities of reuse or recycle of wastes into something 
meaningful thus expanding the life cycle of any material that would otherwise go to 
landfill and pollute the environment we live in.
<br />
 This shall also aid in wise utilization of 
resources as when goods are manufactured by recovering from wastes, no new 
materials are harvested or mined from the nature helping in the natural healing 
process and demotivating the overexploitation of resources that the earth can 
regenerate in a certain time frame.
<br />

        <ToggleDisplay if={this.state.show} tag="section">
 
<br />
<Col xs={18} md={14} className="m-auto">
                <Image src= {benifits} fluid />
                </Col>
The circular economy model usually benefits the people who live in low-income areas 
and since they are the most vulnerable groups to experience the effects of poor 
waste management and the problems that arise along with its aftermath, it wouldn't 
be wrong to say that the circular economy model is an inclusive ideology. People 
living in low-income areas of a big city are the ones who usually have a lifestyle that 
makes it unaffordable for them to utilize the services provided by the government or 
the private sector associated with waste management because of the costly and 
inefficient disposal at overfilled dumpsites. When generally looked at the situation of 
the world, it can be concluded that the private sector usually overlooks incomegenerating
 opportunities that come from waste management such as recycling and 
composting. Uncollected waste causes severe health and environmental problems 
and represents a missed opportunity from development and economic perspective. 
This can be generally associated and rooted with inefficient educational systems that 
do not preach the importance of circular economy and the advantages it brings to 
the quality of life of people.
<br />
<br />
The hardest thing to do is start with changing the system and manage a platform or a 
market for an alternative waste value chain. The management of missing links in a 
poorly managed waste value chain such as recycling and sorting centers, 
composting facilities for the organic waste treatment, and also a marketplace for the 
value-based recovered waste materials. In most of the developing countries, there 
seems to be a problem in extensive research methods on operationalizing new 
recycling technologies as well as strengthening existing recycling industries.
<br />
<br />
So why would a country like Nepal invest in a Circular Economy model? Don’t we 
have other basic issues still unsolved which should be prioritized before waste 
management?
<br />
<br />
A Circular Economy Model will solve half of our problems to a certain extent. The 
circular Economy believes in education for all, a safe environment for all, cleanliness 
for all, opportunities for all, and not leaving even a single stone unturned for resource 
recovery and management. The World Bank claims that waste production is growing 
faster than urbanization rates and since Nepal has an ever-growing trend of moving 
to larger cities, this trend has immensely affected the waste management system and 
overwhelmed landfills. The rich people and their neighborhoods usually do not have 
to deal with this but the whole weight falls upon low-income neighborhoods that have 
high population density and lack of infrastructure. This is a vicious cycle of problems 
for the poor.
<br />
<br />
The most important benefit of circular economy is thus, its inclusivity. It allows lowincome families to learn and gain value from their waste resources and it also keeps 
their surroundings clean (the problem of sanitation is usually a big deal in low-income 
societies because of expensive management fees).
<br />
<br />
The circular economy model shall also include entrepreneurs and smart people to act 
as a private sector when it comes to the management of wastes and create revenue 
streams in unconventional ways by using technology. It aids in being a value for 
money management system as resource recovery lies at the heart of this value chain 
and also is an environmentally conscious step that makes entrepreneurs run their 
business ventures like a social enterprise.
<br />
<br />
The main problems, however, seem to be poor institutional capacity and lack of will 
among people in power to address the problems of waste management.
<br />
<br />
To address these, we can conclude the following things about the circular economy:
<br />
<br />
1. It means transitioning a disposal-driven waste sector towards a more 
   sustainable, recycle, reuse, reduce, repurpose and resource recovery-driven 
   waste sector.
   <br />
2. Since it motivates affordability, collection and management rates are going to 
peak like never before in the waste sector.
<br />

3. This will in turn directly affect the quality of life of people living in service areas, 
mostly in sensitive sectors like health and sanitation.
<br />
4. This shall be good for the environment, reduce GHG emissions, turn wastes into 
value-based end products and thus, contribute to an economical level also. It 
shall help in direct and indirect job creation.
<br />
5. This idea shall raise awareness among people which will make it easy to source 
cleaner and well-segregated waste products. This shall aid in improving supply 
chains which will, in turn, add value to undervalued waste to value products 
market, it is a process.
<br />
6. Optimization of opportunity costs of resources by efficient management 
practices can be largely expected.
</ToggleDisplay>
<Button onClick={ () => this.handleClick()} variant ="success" size = "sm">{title}</Button>
</>
        )}

  }

            