import React from 'react'
import {useState, useEffect} from 'react';
import {Nav as Navv, NavLinks, NavBtn, NavBtnLink} from '../../Navbar/NavbarElements';
import { FaBars }  from 'react-icons/fa';
import {Dropdown, Nav,Navbar as Navvbar, NavDropdown, Form,FormControl, Button, NavLink} from 'react-bootstrap'
import greenlogo from "../../../images/greenlogo.svg"
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import {Link} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import{animateScroll as scroll} from 'react-scroll';
import { SocialLogo } from "../../Footer/FooterElements";

const BlogNavbar = () => {
    return(
         <Navvbar bg = "white" expand="lg"  sticky="top">
                 <Navvbar.Brand href = "home" >
                <img
                   src ={greenlogo}
                   alt= " "
                   width ="100"
                   height = "100"
                   className= "d-inline-block align-top"
                   />
                   </Navvbar.Brand>
  <Navvbar.Toggle aria-controls="navbarScroll" />
  <Navvbar.Collapse id="navbarScroll">
    <Nav
    
      className="ml-auto"
      style={{ maxHeight: '250px' }}
      navbarScroll
    >
        <Nav.Link  className = "linkText">
        <NavLinks to = "first">INSIDE GREEN ROAD</NavLinks> 
        </Nav.Link>

        <Nav.Link className = "linkText">
        <NavLinks to = "second">GREEN TECH</NavLinks>
        </Nav.Link>
       
        <Nav.Link className = "linkText">
        <NavLinks to = "third">ClIMATE CHANGE</NavLinks>
        </Nav.Link>

        <Nav.Link className = "linkText">
        <NavLinks to = "fourth">ECO STORIES</NavLinks>
        </Nav.Link>
    </Nav>
    
  </Navvbar.Collapse>
</Navvbar>
    )}
export default BlogNavbar