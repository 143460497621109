import styled from 'styled-components'
export const BlogContainer = styled.div
`
height: 300px;
display: flex;
flex-direction: column;
justify-con-items: center;
background: #00B74A;

@media screen and (max-width: 1000px){
    height: 350;
}
@media screen and (max-width: 768px){
    height: 350;
}
@media screen and (max-width: 480px){
    height: 350;
}

`
// export const BlgContainer = styled.div
// `
// height:700px;
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
// // background: #EBECF0;
// background: #fafafa;
// `

export const BlogC = styled.div
`
height: auto;
display: flex;

flex-direction: column;
justify-content: center;
align-items: center;
background: #fafafa;

@media screen and (max-width: 1000px){
    height: auto;
}
@media screen and (max-width: 768px){
    height: auto;
}
@media screen and (max-width: 480px){
    height: auto;
}

`

export const BlogWrapper= styled.div
`
z-index: 1;

padding: 0 24px;
justify-content: center;
align-items:center;

// @media screen and (max-width: 768px){
//     height: 650px;
// }
// @media screen and (max-width: 480px){
//     height: 600px;
// }
`
export const ContentA= styled.p
`
font-size: 1rem;
padding-top: 10px;
color: #000000;

`
export const TextWrapper = styled.div
`
padding-top: 0px;
// padding-bottom: 60px;

@media screen and (max-width: 768px){
    height: auto;
}
@media screen and (max-width: 480px){
   height: auto;
}
`

export const Heading = styled.h1
`
font-size: 2.6rem;
font-wieght: bold;
color: #ffffff;
text-align: center;
@media screen and (max-width:768px){
    font-size: 1.7rem;
}
`
export const ContentB= styled.p
`
font-size: 1rem;
padding-top: 10px;
color: #000000;
text-align: center;

`
export const ContentC= styled.h2
`
font-size: 1rem;
padding-top: 10px;
color: #000000;
text-align: center;
font-weight: bold
`

export const ImgWrap = styled.div
`
max-width: 50px;
height: 40px
justify-content: right;
display: flex;
flex-direction: row;
align-items: right;
`

export const Img = styled.img
`
float: right;
height: 40px;
width: 50px;
margin-bottom: 10px;

// @media screen and (max-width: 768px){
//     height: 390px;
//     width: 390px;
//     // padding-top: 10px;
    
// }
// @media screen and (max-width: 480px){
//     height: 300px;
//     width:300px;
// }
`
export const ImgA = styled.img
`
height: 360px;
width: 400px;
margin-bottom: 10px;
margin-left: 500px;


@media screen and (max-width: 768px){
    height: 390px;
    width: 390px;
    // padding-top: 10px;
    
}
@media screen and (max-width: 480px){
    height: 300px;
    width:300px;
}
`
export const ImgB = styled.img
`
height: 400px;
width: 600px;
margin-bottom: 10px;
// margin-left: 400px;
align-items: center;
justify-content: center;



@media screen and (max-width: 768px){
    height: 390px;
    width: 390px;
    // padding-top: 10px;
    
}
@media screen and (max-width: 480px){
    height: 300px;
    width:300px;
}
`

export const BWrapper = styled.div
`
max-width: 1000px;
 margin:  auto;
display: grid;
grid-template-columns: 1fr;
align-items: center;
grid-gap:20px;
padding: 0 50px;
 



@media screen and (max-width: 1000px){
    grid-template-columns: 1fr ;
}

@media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
}
@media screen and (max-width: 480px){
   grid-template-columns: 1fr;
}
@media screen and (max-width: 320px){
    grid-template-columns: 1fr;
 }
}
`
export const BCard = styled.div
`
margin-top: 50px;
background: #fafafa;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 10px;
max-height: 3000px;
max-width: 900px;
padding: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;

&: hover {
    transform: scaled(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

`

export const B1Card = styled.div
`
margin-top: 50px;
background: #EBECF0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 10px;
max-height: 3000px;
max-width: 900px;
padding: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;

&: hover {
    transform: scaled(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

`
export const BIcon = styled.img
`
height: 160px;
width: 160px;
margin-bottom: 10px;
@media screen and (max-width: 1000px){
    height:100px;
    width: 100px;
}
@media screen and (max-width: 768px){
    height:120px;
    width: 120px;
}

@media screen and (max-width: 482px){
    height:100px;
    width:100px;
}


`

export const BH1= styled.h1
`
font-size: 2.3rem;
color:#00B74A;
margin-bottom: 15px;
margin-top:95px;
font-weight: bold;
@media screen and (max-width: 768px){
    font-size: 1.3rem;
}

@media screen and (max-width: 480px){
    font-size: 1.3rem;
}
`
export const BH= styled.h1
`
font-size: 2.3rem;
color:#00B74A;
margin-bottom: 15px;
margin-top:50px;
font-weight: bold;
@media screen and (max-width: 768px){
    font-size: 1.3rem;
}

@media screen and (max-width: 480px){
    font-size: 1.3rem;
}
`

export const BH2= styled.div
`
color: #00B74A;
font-size: 1rem;
margin-bottom: 10px;
font-weight: bold;

@media screen and (max-width: 768px){
    font-size: 1rem;
}

@media screen and (max-width: 480px){
    font-size: 0.95rem;
}
`
export const BParagraph = styled.p
`
color: #ffffff;
font-size: 1rem;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 0.90rem;
}

@media screen and (max-width: 480px){
    font-size: 0.90rem;
}

`
export const BP = styled.p
`
color: #000000;
font-size: 1rem;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 0.90rem;
}

@media screen and (max-width: 480px){
    font-size: 0.90rem;
}

`