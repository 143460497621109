export const homeObjTwo = {
    id: 'discover',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    // topLine: '',
    headline: 'WHY GREENROAD?',
    description:'GRWM is founded with an aim to aid environment-friendly sustainable road development.',
    buttonlabel: 'LEARN MORE',
    des1: ' 100% Greener: Disposal of plastic will no longer be a problem.',
    des2: ' Almost 3 times durable: It is almost 3 times durable.',
    des3: ' 2.5 X Load Bearing Capacity: Use of plastics increases the load-bearing capacity of the road.',
    des4: '10% Cheaper: It can reduce the cost by 10% of that of traditional paving.',
    imgStart: false,
    // img: require('../../images/powerful.svg'),
    alt: '', 
    dark : true,
    primary: true,
    darkText: false
}