import React, {useState} from 'react';
import {Form, Button, ButtonGroup, ToggleButton} from 'react-bootstrap';
import CountUp from "react-countup";
import { FaInfoCircle } from 'react-icons/fa';
// import { Heading } from '../InfoSection/InfoElements';
import { NewsH1 } from '../NewsSection/NewsElements';
 
import { BCard, BWrapper, CalContainer, CalRow, CalWrapper,Heading, Column1, Column2,CalculateH1, Subtitle, TextWrapper, TopLine } from './CalculationElements';

const Calculation = () => {
  let depth = 0.04;
 
   const [radioValue, setRadioValue] = useState('1');
    const[length, setLength] = useState('');
    const[width, setWidth] = useState('');
    const[volume, setVolume] = useState(Math.round(length*width*depth));
    const[consumed, setConsumed]= useState(Math.round(volume * 10.5*3));
    const[saved, setSaved] = useState(Math.round(consumed*0.95)/3);
    const[space, setSpace] = useState(Math.round(consumed*35*7)/1000);

   
    function volume1(){
      setVolume(Math.round(length*width*depth));
    }
    function consumed1(){
      setConsumed(Math.round(volume*10.5*3));
    }
    function saved1(){
      setSaved(Math.round(consumed*0.95)/3);
    }
    function space1(){
 
      setSpace(Math.round((consumed * 35 *7)/1000));
    }

    function logicCalculation(){
      const tempvolume = Math.round(length * width * depth);
      const tempconsumed1 = Math.round(tempvolume * 10.5 * 3);
      const tempsaved1 = Math.round((tempconsumed1 * 0.95)/3);
      const tempsetSpace = Math.round((tempsaved1 * 35 * 7) / 1000);
      setVolume(tempvolume);
      setConsumed(tempconsumed1);
      setSaved(tempsaved1);
      setSpace(tempsetSpace);
    }   
    // const radios = [
    //   { name: 'Himal', value: '8.4' },
    //   { name: 'Pahad', value: '10.5' },
    //   { name: 'Terai', value: '12.6' },
    // ];
  
    // return (
    //   <>
      
    // 
    //     <ButtonGroup toggle className="mb-2">
        
    //     </ButtonGroup>
        
    //     <ButtonGroup toggle>
    //       {radios.map((radio, idx) => (
    //         <ToggleButton
    //           key={idx}
    //           type="radio"
    //           variant="success"
    //           name="radio"
    //           value={radio.value}
    //           checked={radioValue === radio.value}
    //           onChange={(e) => setRadioValue(e.currentTarget.value)}
    //         >
    //           {radio.name}
    //         </ToggleButton>
    //       ))}
    //     </ButtonGroup>
    //     <br />
    //     <br />
    return (
      <>
        <CalContainer id= "calculation">
          <CalWrapper>
            <CalculateH1>IMPACT CALCULATION</CalculateH1>
            <CalRow>
              <Column1>
                <Form>
                  <Form.Group controlId="length">
                    <Form.Label>Enter length of the road: </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="length"
                      value={length}
                      onChange={(e) => setLength(+e.target.value)}
                    />
                    <Form.Text className="text-muted">in meters</Form.Text>
                  </Form.Group>

                  <Form.Group controlId="width">
                    <Form.Label>Enter width of the road: </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="width"
                      value={width}
                      onChange={(e) => setWidth(+e.target.value)}
                      // onChange = {() => volume1()}
                    />

                    <Form.Text className="text-muted">in meters</Form.Text>
                  </Form.Group>
                  {/* <Form.Label>Volume: </Form.Label>
    <input value = {volume} onChange = { e => setVolume(+e.target.value)} />
     */}
                  <Button
                    variant="success"
                    className="mb-2"
                    onClick={() => {
                      logicCalculation();
                      // volume1();
                      // consumed1();
                      // saved1();
                      // space1();
                    }}
                  >
                    Calculate
                  </Button>
                </Form>
              </Column1>
              <Column2>
                <TextWrapper>
                  <Heading>
                    CO2 emission offset: <CountUp end={consumed} /> kgs
                  </Heading>
                  <br />

                  <Heading>
                    Bitumen Saved: <CountUp end={saved} /> kgs
                  </Heading>
                  <br />
                  <Heading>
                    Space Saved in landfill: <CountUp end={space} /> cubic feet{" "}
                  </Heading>
                  <br />
                </TextWrapper>
              </Column2>
            </CalRow>
          </CalWrapper>
        </CalContainer>
      </>
    );
  }
  

export default Calculation
