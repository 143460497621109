import styled from 'styled-components'
export const WorkContainer= styled.div
`
height: 700px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #EBECF0;

@media screen and (max-width: 1000px){
    height: 800px;
}
@media screen and (max-width: 768px){
    height: 1400px;
}
@media screen and (max-width: 480px){
    height: 1350px;
}
@media screen and (max-width: 360px){
    height: 1350px;
    width:360px;
}
`
export const WorkWrapper = styled.div 
`
max-width: 1000px;
margin: 0 auto;
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
align-items: center;
grid-gap:20px;
padding: 0 50px;

@media screen and (max-width: 1000px){
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
}
@media screen and (max-width: 480px){
   grid-template-columns: 1fr;
}
@media screen and (max-width: 320px){
    grid-template-columns: 1fr;
 }
`
export const WorkCard= styled.div
`
background: #fff;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 10px;
max-height: 350px;
max-width: 300px;
padding: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;

&: hover {
    transform: scaled(1.02);
    transition: all 0.2s eae-in-out;
    cursor: pointer;
}

@media screen and (max-width: 1000px){
    max-height: 300px;
    max-width:300px;
}

@media screen and (max-width: 768px){
    max-height: 350px;
    max-width: 350px;
}
@media screen and (max-width: 480px){
    max-height: 400px;
    max-width:350px;
}
@media screen and (max-width: 320px){
    max-height: 400px;
    max-width:350px;
}
`
export const WorkIcon = styled.img
`
height: 160px;
width: 160px;
margin-bottom: 10px;
@media screen and (max-width: 1000px){
    height:100px;
    width: 100px;
}
@media screen and (max-width: 768px){
    height:120px;
    width: 120px;
}

@media screen and (max-width: 482px){
    height:100px;
    width:100px;
}

`
export const WorkH1 = styled.h1
`
font-size: 2.3rem;
color: #00B74A;
margin-top: 40px;
margin-bottom: 54px;
font-weight: bold;

@media screen and (max-width: 768px){
    font-size: 1.4rem;
    margin-top: 40px;
}

@media screen and (max-width: 480px){
    font-size: 1.4rem;
    margin-top: 20px;
}
`
export const WorkH2= styled.div
`
color: #00B74A;
font-size: 1rem;
margin-bottom: 10px;
font-weight: bold;
@media screen and (max-width: 768px){
    font-size: 1rem;
}

@media screen and (max-width: 480px){
    font-size: 0.95rem;
}
`

export const WorkP = styled.p
`
color: #000000;
font-size: 1rem;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 0.90rem;
}

@media screen and (max-width: 480px){
    font-size: 0.90rem;
}

`

