import React from 'react'
// import {Button} from '../ButtonElement';
import {Button} from 'react-bootstrap';
import { InfoContainer, InfoRow, InfoWrapper,Column1,Column2,
    TextWrapper,TopLine,Heading,
    Subtitle,BtnWrap,ImgWrap,Img} from './InfoElements';
    import team from '../../images/team.svg'
    import {NavBtn, NavBtnLink} from '../../components/Navbar/NavbarElements';

const InfoSection = ({lightBg,id,img,imgStart,topline,lightText,darkText,
headline,description,buttonlabel, alt, primary, dark, dark2}) => {
    return (
        <>
        <InfoContainer lightBg = {lightBg} id = "team">
            <InfoWrapper>
                <InfoRow imgStart = {imgStart}>
                    <Column1>
                    <TextWrapper>
                        <TopLine>{topline}</TopLine>
                        <Heading lightText={lightText}>{headline}</Heading>
                        <Subtitle darkText={darkText}>{description}</Subtitle>
                        {/* <BtnWrap>
                            <Button to = '/team'
                            smooth= {true}
                            duration= {500}
                            spy= {true} 
                            exact = "true"
                            offset = {80}
                            primary={primary ? 1 : 0}
                            dark = {dark ? 1: 0}
                            dark2 = {dark2 ? 1: 0}
                            
                            >{buttonlabel}</Button>
                        </BtnWrap> */}
                        <Button href = "/about" variant="success"> Meet our team</Button>
                    </TextWrapper>
                    </Column1>
                    <Column2>
                    <ImgWrap>
                    <Img  src= {team} alt={alt}/>       
                    </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
        </>
    )
}

export default InfoSection

















