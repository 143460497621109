export const homeObjOne = {
    id: 'team',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    // topLine: '',
    headline: 'Interested! Want to know who is behind this?',
    description:'We are team up young & dynamic problem solvers who want to solve our environmental problems with sustainable solution',
    buttonlabel: 'Meet the team',
    imgStart: true,
    img: require('../../images/team.svg'),
    alt: '', 
    dark : true,
    primary: true,
    darkText: false
}
