import React from 'react';
import Icon1 from '../../images/cart.svg'
import Icon2 from '../../images/cleaning.svg'
import Icon3 from '../../images/processing.svg'
import Icon4 from '../../images/road.svg'
import {WorkContainer, WorkCard, WorkH1, WorkH2,WorkIcon,WorkP,
WorkWrapper} from './WorkElements';
const Work = () => {
    return(
        <WorkContainer id ="how">
            <WorkH1>HOW IT WORKS?</WorkH1>
            <WorkWrapper>
                
                <WorkCard>
                    <WorkIcon src ={Icon1} />
                    <WorkH2>COLLECTION</WorkH2>
                    <WorkP>Waste plastics are collected from around the city.</WorkP>
                    
                </WorkCard>
                <WorkCard>
                    <WorkIcon src ={Icon2} />
                    <WorkH2>CLEANING</WorkH2>
                    <WorkP>Collected plastics will be cleaned before processing.</WorkP>
                    
                </WorkCard>
                <WorkCard>
                    <WorkIcon src ={Icon3} />
                    <WorkH2>PROCESSING</WorkH2>
                    <WorkP>Cleaned plastics now gets processed in our machines.</WorkP>
                    
                </WorkCard>
                <WorkCard>
                    <WorkIcon src ={Icon4} />
                    <WorkH2>LAYING</WorkH2>
                    <WorkP>Processed plastics, ingredients are ready for laying.</WorkP>
                    
                </WorkCard>
            </WorkWrapper>
        </WorkContainer>
    )
}
export default Work