
import React, {useState} from 'react';
import { GlobalStyle } from '../../globalStyle';

import {Container,Button } from './ModalElements';
import { ModalInner } from './ModalInner';
function ModalC(){
  const [showModal, setShowModal]= useState(false);

  const openModal = () => {
    setShowModal(prev => !prev);
  };
  return(
    <>
    <Container>
      <Button onClick = {openModal}>BOOK NOW</Button>
      <ModalInner showModal = {showModal} setShowModal = {setShowModal} />
      <GlobalStyle />

    
    </Container>
    </>
  );
}
export default ModalC;