import React, {useState} from 'react'
import { BlogContainer, BlogWrapper, ContentA, Heading, Img, ImgWrap, TextWrapper, BlogC } from '../BlogElements'
import values from '../../../images/values.png'
import { Subtitle } from '../../InfoSection/InfoElements';
import { IntroHeading, IntroP, IntroSubtitle} from '../../about/intro/IntroElements';

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <ContentA>
        {isReadMore ? text.slice(0, 217) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? "...Read more" : " Show less"}
        </span>
        </ContentA>
    );
    };
const Values = () => {
    return (
        
        <BlogC>
            <BlogWrapper>
                <IntroHeading> Our Values</IntroHeading>
                <IntroSubtitle>
                    A sustainable economic model for the sustainability of earth</IntroSubtitle>
                <TextWrapper>
                    {/* <ImgWrap>
                    <Img src = {values}>
                    </Img>
                    </ImgWrap> */}
                    <IntroP>
                        <ReadMore>

                        We are a team of young & dynamic problem solvers who want to 
                        solve our environmental problems with sustainable solutions. Our actions are deep-rooted for
                         the wellbeing of societies & environment. We value mother nature greatly for her blissful earth &
                          it’s our responsibility to preserve it & transfer to our coming generations. We are inclined towards wise 
                          utilization of resources as when the sustainable economy is practiced, 
                        new resources are consumed less, thus helps in sustaining nature.
</ReadMore>
</IntroP>
                </TextWrapper>
        
            </BlogWrapper>
        </BlogC>

    )
}

export default Values
