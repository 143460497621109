import React, { useState } from 'react'
import HeroSection from '../components/HeroSection';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import InfoSection from '../components/InfoSection';
import {homeObjOne}from '../components/InfoSection/Data';
import News from '../components/InfoSection/News'
import Services from '../components/Services';
import Work from '../components/Work';
import NewsSection from '../components/NewsSection';
import InfoTwo from '../components/InfoTwo';
import {homeObjTwo} from '../components/InfoTwo/Data';
import Footer from '../components/Footer';
import Calculation from '../components/Calculation';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
       <>
       {/* <Sidebar isOpen= {isOpen} toggle = {toggle} /> */}
       <Navbar toggle = {toggle} />
        <HeroSection />
        <Calculation />
       <Work />
      <InfoTwo {...homeObjTwo} />
       <Services />
       <InfoSection {...homeObjOne} />
       <NewsSection />
       <Footer />
       </>
    )
}

export default Home
