import styled from 'styled-components'
export const TeamContainer = styled.div
`
height:auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #EBECF0;
padding-bottom : 50px;
@media screen and (max-width: 1500px){
    height: auto;
}

@media screen and (max-width: 1000px){
    height: auto;
}
@media screen and (max-width: 768px){
    height: auto;
}
@media screen and (max-width: 480px){
    height: auto;
}
@media screen and (max-width: 360px){
    height: auto;
   
}
`
export const TeamWrapper = styled.div
`
max-width: 2000px;
margin: 0 auto;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
align-items: center;
grid-gap:20px;
padding: 0 50px;
align-items: center


@media screen and(max-width: 1500px ){
grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 1000px){
    grid-template-columns: 1fr;
}

@media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
}
@media screen and (max-width: 480px){
   grid-template-columns: 1fr;
}
@media screen and (max-width: 320px){
    grid-template-columns: 1fr;
 }
}
`
export const TeamCard = styled.div
`
background: #fff;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 10px;
max-height: 500px;
max-width:500px;
padding: 20px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;

`
export const TeamIcon = styled.img
`
height: 350px;
width: 300px;
margin-bottom: 10px;

background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;

position: relative;
z-index: 0;


&:before {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}



`

export const TeamH1= styled.h1
`
padding-top: 20px;
padding-bottom: 10px;
font-size: 2.3rem;
color: #00B74A;
margin-bottom: 64px;
font-weight: bold;
@media screen and (max-width: 768px){
    font-size: 1.8rem;
}

@media screen and (max-width: 480px){
    font-size: 1.5rem;
}
`
export const TeamH2= styled.div
`
color: #00B74A;
font-size: 1rem;
margin-bottom: 10px;
font-weight: bold;
@media screen and (max-width: 768px){
    font-size: 1rem;
}

@media screen and (max-width: 480px){
    font-size: 0.95rem;
}
`
export const TeamP = styled.p
`
color: #00B74A;
font-size: 1rem;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 0.90rem;
}

@media screen and (max-width: 480px){
    font-size: 0.90rem;
}
`