import styled from 'styled-components'
export const IntroContainer = styled.div
`
display: flex;
height: auto;
background: #fafafa;
padding-top: 0px;
padding-bottom: 10px;


@media screen and (max-width: 1000px){
    height: auto;
}
@media screen and (max-width: 768px){
    height: auto;
}
@media screen and (max-width: 480px){
    height: auto;
}
`
export const IntroWrapper = styled.div
`
max-height: auto;
justify-content: center;
align-items: center;
padding: 0 50px;
box-shadow: 0 1px 3px rgba(0,0,0,0);
transition: all 0.2s ease-in-out;

&: hover {
    transform: scaled(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0,0,0,0);
    max-height:auto;
  
}

`
export const IntroHeading = styled.h1
`
font-size: 2rem;
font-weight: bold;
color: #00B74A;
text-align: center;
padding-bottom: 20px;

@media screen and (max-width:768px){
    font-size: 1.5rem;
}
`
export const IntroSubtitle = styled.h2
`
font-size:1.5rem;
padding-top: 10px;
color: #00B74A;
padding-bottom:10px;
text-align: center;
`
export const IntroP = styled.p
`
font-size: 1rem;
color: #000000;

`