import styled from 'styled-components'

export const InfoContainer= styled.div
`
color: #fff;
background: ${({ lightBg }) => (lightBg ? '#EBECF0': '#fafafa')};
padding-top: 10px;
// @media screen and (max-width: 768px){
//     padding:-40px 0;
}

`
export const InfoWrapper = styled.div
`
display: grid;
z-index: 1;
height: 600px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;
justify-content: center;

@media screen and (max-width: 768px){
    height: 750px;
}
@media screen and (max-width: 480px){
    height: 800px;
}
`
export const InfoRow = styled.div
`
display: grid;
grid-auto-columns: minmax(auto, 1.5fr);
align-items: center;
 grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'`: `'col1 col2'`)};

@media screen and (max-width: 760px){
    grid-template-areas: ${({imgStart}) =>(imgStart ? `'col1''col2'` : `'col2 col1'`)};
}
`
export const Column1 = styled.div
`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
`
 export const Column2 = styled.div
 `
 max-width: 15px;
 padding-top: 0 15px;
 grid-area: col2
 `
export const TextWrapper = styled.div
`
max-width: 540px;
padding-top: 0px;
// padding-bottom: 60px;

@media screen and (max-width: 768px){
    padding-top:25px;
}
@media screen and (max-width: 480px){
   padding-top: 25px;
}
`
export const TopLine = styled.p
`
color: #01bf71;
font-size: 16px;
line-height: 0;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
//margin-bottom: 16px;
`
export const Heading = styled.h1
`
margin-bottom: 16px;

font-size: 38px;
line-height: 1.1;
font-weight: 600;
color: ${({lightText}) => (lightText ? '#00B74A': '#00B74A')};

@media screen and (max-width: 400px){
    font-size: 32px;
}
`
export const Subtitle= styled.p
`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({darkText}) => (darkText ? '#000000': "#000000")};
`
export const BtnWrap = styled.div
`
display: flex;
justify-content: flex-start;
margin-bottom: -180px;
`
export const ImgWrap = styled.div
`
max-width: 555px;
height: 100%;

`
export const Img = styled.img
`
height: 460px;
width: 460px;
margin-bottom: 50px;

@media screen and (max-width: 768px){
    height: 400px;
    width: 400px;
    // padding-top: 10px;
    
}
@media screen and (max-width: 480px){
    height: 400px;
    width:300px;
}
`