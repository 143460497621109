import styled from 'styled-components'
export const MissionContainer = styled.div
`
height: auto;
color: #fff;
background: ${({lightBg}) => (lightBg ? '#EBECF0': '#fafafa')};
background: #fafafa;
padding-top: 10px;
padding-bottom: 0px;

@media screen amd (max-width: 868px){
    // padding: -40px 0;
    height: auto;
}

@media screen and (max-width: 768px){
    height: auto;
}

@media screen and (max-width: 400px){
    height: auto;
}
@media screen and (max-width: 1068px){
    height: auto;
}
`

export const MissionWrapper = styled.div
`
display: grid;
z-index: 1;
height: auto;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;
justify-content: center;
`

export const MissionRow = styled.div
`
display: grid;
grid-auto-columns: minmax(auto, 1.5fr);
align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'`: `'col1 col2'`)};

@media screen and (max-width: 768px){
    grid-template-areas: col1;
    max-width: 768px;
}
`
export const Column1 = styled.div
`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
max-width:540px;
@media screen and (max-width: 768px){
    max-width:540px;
}
`
export const Column2 = styled.div
`
max-width: 15px;
padding-top: 0 15px;
grid-area: col2

`
export const TextWrapper = styled.div
`
max-width: 540px;
padding-top: 0px;
padding-bottom: 60px;

@media screen and (max-width: 768px){
    padding-top:50px;
    max-width:508px;
    justify-content: center;
    text-align: center;
}
@media screen and (max-width: 480px){
   padding-top: 50px;
   max-width: 508px;
   justify-content: center;
}
`
export const TopLine = styled.p
`
color: #01bf71;
font-size: 16px;
line-height: 0;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
// margin-bottom: 16px;
`
export const Heading = styled.h1
`
margin-bottom: 16px;

font-size: 1.8rem;
line-height: 1.1;
font-weight: 600;
color: ${({lightText}) => (lightText ? '#00B74A': '#00B74A')};

@media screen and (max-width: 400px){
    font-size: 1.5rem;
}
`

export const MissionH1= styled.h1
`
padding-top: 20px;
text-align: center;
padding-bottom: 10px;
font-size: 2.3rem;
color: #00B74A;
margin-bottom: 64px;
font-weight: bold;
@media screen and (max-width: 768px){
    font-size: 1.8rem;
}

@media screen and (max-width: 480px){
    font-size: 1.5rem;
}
`
export const Subtitle= styled.p
`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({darkText}) => (darkText ? '#000000': "#000000")};
`
export const BtnWrap = styled.div
`
display: flex;
justify-content: flex-start;
margin-bottom: -120px;
`
export const ImgWrap = styled.div
`
max-width: 555px;
height: 100%;

`
export const Img = styled.img
`
height: 360px;
width: 360px;
margin-bottom: 10px;
margin-left: 100px;

@media screen and (max-width: 768px){
    height:300px;
    width: 300px;
    margin-left: 20px;
    // display: none;
    
}
@media screen and (max-width: 480px){
    height: 300px;
    width: 300px;
    margin-left:20px;
    // display: none;
}
`
