import styled from 'styled-components'

export const InfoTwoContainer= styled.div
`
color: #fff;
background: ${({ lightBg }) => (lightBg ? '#EBECF0': '#fafafa')};

@media screen and (max-width: 768px){
    padding: 5px 0;
}
`
export const InfoTwoWrapper = styled.div
`
display: grid;
z-index: 1;
height: 650px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;

justify-content: flex-start;
`
export const InfoTwoRow = styled.div
`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
 grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1 col3'`: `'col1 col2 col3'`)};

@media screen and (max-width: 760px){
    grid-template-areas: ${({imgStart}) =>(imgStart ? `'col2 col1 col3'` : ` 'col1 col2 col3'`)}
}
`
export const Column1 = styled.div
`

margin-bottom: 15px;
 padding-top: 0px;
grid-area: col1;
`
 export const Column2 = styled.div
 `
 max-width: 15px;
 padding-top: 5px;
 grid-area: col2;
 margin-left: 200px;

 @media screen and (max-width: 768px){
    margin-left:60px;
}

 `
 export const Column3 = styled.div
 `
 max-width: 600px;
 padding-top: 0 15px
 grid-area: col3;
 margin-top: 15px;

 
 @media screen and (max-width: 1000px){
    max-width: 600px;
 padding-top: 0 15px
 grid-area: col3;
 margin-top: 15px;
}

 @media screen and (max-width: 898px){
    display: none;
}

 
 `
export const TextWrapper = styled.div
`
max-width: 540px;
padding-top: 10px;
padding-bottom: 60px;
`
export const TopLine = styled.p
`
 color: #01bf71;
 font-size: 16px;
 line-height: 16px;
 font-weight: 700;
 letter-spacing: 1.4px;
 text-transform: uppercase;
 margin-bottom: 16px;
`
export const Heading = styled.h1
`
padding-top:10px;
margin-top:10px;
margin-bottom: 24px;
font-size: 38px;
line-height: 1.1;
font-weight: 600;
color: ${({lightText}) => (lightText ? '#00B74A': '#00B74A')};

@media screen and (max-width: 400px){
    font-size: 32px;
}
`
export const Subtitle= styled.p
`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({darkText}) => (darkText ? '#000000': "#000000")};
`
export const InfoP= styled.p
`
color: #000000;
max-width: 440px;
margin-bottom: 35px;
font-size: 14px;
line-height: 24px;
font-weight:400;
padding-bottom:20px;

@media screen and (max-width: 900px){
    font-size: 9px;
    
}

@media screen and (max-width: 1200px){
    font-size: 11px;
    
}
`

export const BtnWrap = styled.div
`
display: flex;
justify-content: flex-start;
`
export const ImgWrap = styled.div
`
max-width: 555px;
height: 100%;
`
export const Img = styled.img
`
height: 100px;
width: 100px;
margin-bottom: 10px;


@media screen and (max-width: 768px){
    display: none;
    
}
`