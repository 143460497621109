import React, { useState, Component} from "react";
import { IntroContainer,IntroP, IntroHeading, IntroSubtitle,IntroWrapper } from "./IntroElements";
import ToggleDisplay from 'react-toggle-display';
import {Col, Button} from 'react-bootstrap';
import { BCard } from "../../blogs/BlogElements";


export default class Content extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { show: false };
  }
 
  handleClick() {
    this.setState({
      show: !this.state.show
    });
  }

  render(){
    const { show } = this.state;
    let title = this.state.show? "Show less": "Read more";

        return(
        
	<IntroContainer>
        <IntroWrapper>
        
                <IntroHeading>Who We Are</IntroHeading>
                <IntroSubtitle>A social enterprise on a mission to eliminate the idea of Plastic waste.</IntroSubtitle>
	<IntroP>
       
        Green Road Waste Management Pvt. Ltd.an innovative waste management 
                    enterprise that was established in April 2018 to create values from waste
                     and solve solid waste management-related problems especially plastic waste.	
                    <br />
                    <br />
                     We started with the solution of plastic wastes that businesses, governments,
                       and organizations produce in their daily operations; but will go a step 
                       further to include wasted time, wasted money, and wasted energy. 
                       We started with keeping materials out of landfills and creating a more 
                       circular economy through recycling and reuse but will expand it to include 
                       creating a more efficient and effective system of the waste management 
                       system.
                       <br />
                       <br />
        <ToggleDisplay if={this.state.show} tag="section">
		  
                       As we have started our journey through research & training from Green
                        Road Bhutan and later constructed pilot-scale roads. Up to now, two plastic road projects
                        have been constructed, one in Pokhara (600 m2) and another in Kirtipur Municipality
                        (1,200 m2) and we are going to construct more in the coming days. We are further taking
                         steps in nationalizing the technology by assisting in making standards norms of plastic
                        roads. We are also working on the development & commercialization of green bricks and 
                        plastic-mixed green bricks and. The aim of our company is not to construct plastic- roads
                        like a contractor but to show model roads, standardize and distribute this technology all 
                        over Nepal for the betterment of infrastructure & the environment. We are also very keen to solve plastic waste problems completely through various technology available other than plastic roads. Further more we have initiated Green Recycling Center to collect & segregate waste at Pokhara. We are also
                         taking initiatives on Green Curriculum Development, Eco-classes & Waste Auditing.  
                         <br />
                         <br />
In brief, we have just shortly started, are in a learning phase, and have a vision and 
aspirations to solve plastic problems.
<br />
</ToggleDisplay>
<Button onClick={ () => this.handleClick()} variant ="success" size = "sm">{title}</Button>

        </IntroP>

        </IntroWrapper>
	</IntroContainer>
);
}
}

