import React from 'react'
import {useState, useEffect} from 'react';
import {Nav as Navv, NavbarContainer,NavLogo, MobileIcon, NavMenu,NavItem, NavLinks, NavBtn, NavBtnLink} from './NavbarElements';
import { FaBars }  from 'react-icons/fa';
import {Dropdown, Nav,Navbar as Navvbar, NavDropdown, Form,FormControl, Button, NavLink} from 'react-bootstrap'
import greenlogo from "../../images/greenlogo.svg";
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import {Link} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import{animateScroll as scroll} from 'react-scroll';
import { SocialLogo } from '../Footer/FooterElements';
import logo from '../../images/logo1.png'

const Navbar = () => {
    return(
         <Navvbar bg = "white" expand="lg"  sticky="top">
                 <Navvbar.Brand href = "home" >
                <img
                   src ={greenlogo}
                   alt= " "
                   width ="100"
                   height = "100"
                   className= "container-fluid"
                   />
                   </Navvbar.Brand>
  <Navvbar.Toggle aria-controls="navbarScroll" />
  <Navvbar.Collapse id="navbarScroll">
    <Nav
      className="ml-auto"
    //  className="mr-auto my-2 my-lg-0"
    
      style={{ maxHeight: '350px' }}
      navbarScroll
    >
      <Nav.Link  className = "linkText">
        <NavLinks to = "calculation">CALCULATION</NavLinks> 
        </Nav.Link>

        <Nav.Link  className = "linkText">
        <NavLinks to = "how">HOW IT WORKS?</NavLinks> 
        </Nav.Link>

        <Nav.Link className = "linkText">
        <NavLinks to = "why">WHY GREEN ROAD?</NavLinks>
        </Nav.Link>
       
        <Nav.Link className = "linkText">
        <NavLinks to = "products">PRODUCTS</NavLinks>
        </Nav.Link>

        <Nav.Link className = "linkText">
        <NavLinks to = "team">ABOUT</NavLinks>
        </Nav.Link>

        <Nav.Link className = "linkText">
        <NavLinks to = "news">MEDIA</NavLinks>
        </Nav.Link>

        
        <Nav.Link className = "linkText" href = "/facts">BLOG</Nav.Link>
        <NavLinks to = "blog"></NavLinks>
    
        
    </Nav>
    
  </Navvbar.Collapse>
</Navvbar>
    )}
export default Navbar