import React from 'react'
import Footer from '../../Footer';
import  Navbar from '../../Navbar/index';
import {TeamContainer, TeamH1, TeamP, TeamH2, TeamWrapper,TeamCard, TeamIcon } from '../teamPage/TeamElements'
import bimalbasel from '../../../images/bimalbasel.jpg'
import bimalbastola2 from '../../../images/bimalbastola2.jpg';
import sanjeevbastola2 from '../../../images/sanjeevbastola2.jpg';
import rajivsubedi from '../../../images/rajivsubedi.jpg'
import pratima from '../../../images/pratima.jpg'
import ajay from '../../../images/ajay.jpg'
import nirajan2 from '../../../images/nirajan2.jpg'
import {BrowserRouter as Route} from 'react-router-dom';
import Mission from '../mission';
import Content from '../intro/content';
import Values from '../../blogs/OurValues';
import Intro from '../intro';
import { MDBMask, MDBView, MDBContainer, MDBRow, MDBCol, MDBCollapse } from "mdbreact";

const team = () => {

    return (
        <>
        <Navbar />
         <Mission />
         {/* <Content /> */}
        {/* <Values />  */}
        <TeamContainer id ="team">
          <TeamH1>The Greenopreneurs Leadership</TeamH1>
         <TeamWrapper>
             
        <MDBRow>
        <TeamCard>
          <MDBCol md= "auto">
            <MDBView hover>
              <TeamIcon src ={bimalbasel} />
              <TeamH2>Hom Bahadur Basel, Chairman</TeamH2>
              <MDBMask className="flex-center" overlay="green-strong">
                <p className="white-text">Hom is a dynamic, experienced and a philanthropic businessman of Pokhara. He has been vocal about development gaps in Nepal and is
                 working towards providing clean drinking water access to local communities in Pokhara.</p>
              </MDBMask>
            </MDBView>
            </MDBCol>
            </TeamCard>
            </MDBRow>
        
          
        
          <MDBRow>
          <TeamCard>
          <MDBCol md= "auto">
            <MDBView hover>
              <TeamIcon src ={sanjeevbastola2} />
              <TeamH2>Sanjeev Bastola, M. Director</TeamH2>
              <MDBMask className="flex-center" overlay="green-strong">
                <p className="white-text">Sanjeev has an entrepreneurial mindset, a strong work ethic, and a desire to continuously explore. He is passionate about developing practical solutions that combine his work experience as a founder
                 of Rolling Stones Rock Bar and Lemon Farm House, Pokhara.</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
          </TeamCard>
        </MDBRow>


        <MDBRow>
        <TeamCard>
          <MDBCol md= "auto">
            <MDBView hover>
              <TeamIcon src ={ajay} />
              <TeamH2>Ajay K.C, Technology Director</TeamH2>
              <MDBMask className="flex-center" overlay="green-strong">
                <p className="white-text"> Ajay is technically sound Mechanical Engineer with a degree from Chalmers University. He has worked in Operations, Project Management, Production Planning & Control in various hydropower companies of Nepal and left
                 everything to work for sustainability with us.</p>
              </MDBMask>
            </MDBView>
            </MDBCol>
            </TeamCard>
            </MDBRow>
        
            <MDBRow>
        <TeamCard>
          <MDBCol md= "auto">
            <MDBView hover>
              <TeamIcon src ={bimalbastola2} />
              <TeamH2>Bimal Bastola, Executive Director</TeamH2>
              <MDBMask className="flex-center" overlay="green-strong">
                <p className="white-text">Bimal is passionate about doing things that can create impact. He chose to leave the dream job (working with a reputed Automobile brand JEEP) of many mechanical engineers to pursue his dream as an entrepreneur in his own city
                 and started Ek Call Home Solutions in Pokhara.</p>
              </MDBMask>
            </MDBView>
            </MDBCol>
            </TeamCard>
            </MDBRow>
        
            <MDBRow>
        <TeamCard>
          <MDBCol md= "auto">
            <MDBView hover>
              <TeamIcon src ={nirajan2} />
              <TeamH2>Nirajan Ghimire, Head of R&D</TeamH2>
              <MDBMask className="flex-center" overlay="green-strong">
                <p className="white-text">Nirajan is a Mechanical engineer graduate from Kathmandu University. He worked on the R&D of various projects related to plastics commodities, wind & hydro turbines. His passion for working in research based green projects
                 pulled him to join the team of Green Road.</p>
              </MDBMask>
            </MDBView>
            </MDBCol>
            </TeamCard>
            </MDBRow>

            
            <MDBRow>
        <TeamCard>
          <MDBCol md= "auto">
            <MDBView hover>
              <TeamIcon src ={rajivsubedi} />
              <TeamH2>Rajib Subedi, Chief Civil Engineer</TeamH2>
              <MDBMask className="flex-center" overlay="green-strong">
                <p className="white-text">Rajib graduated from Pokhara Engineering Collage, 
                Nepal with a degree in Civil Engineering. He has a broad experience in the road construction sector of Nepal and currently runs his own construction company at Pokhara.
                 He is interested in fixing infrastructure problems with sustainable solutions.</p>
              </MDBMask>
            </MDBView>
            </MDBCol>
            </TeamCard>
            </MDBRow>

            <MDBRow>
        <TeamCard>
          <MDBCol md= "auto">
            <MDBView hover>
              <TeamIcon src ={pratima} />
              <TeamH2>Pratima Baral, Software Developer</TeamH2>
              <MDBMask className="flex-center" overlay="green-strong">
                <p className="white-text">  Pratima is Computer Engineer graduate from Advanced College of Engineering and Management.
                She believes in hardwork and perseverance, can spend hours in research and development.
                She is self starter, motivated and always enthusiastic in learning new things.</p>
              </MDBMask>
            </MDBView>
            </MDBCol>
            </TeamCard>
            </MDBRow>
        
      </TeamWrapper>
      </TeamContainer>
      <Footer />
      </>
    );
  }

export default team;



