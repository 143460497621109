import React from 'react'
import {Button} from 'react-bootstrap'
import { InfoTwoContainer, InfoTwoRow, InfoTwoWrapper,Column1,Column2,Column3,
    TextWrapper,TopLine,Heading,
    Subtitle,BtnWrap,ImgWrap,Img,InfoP} from './InfoTwoElements';
    import greenenv from '../../images/greenenv.svg'
    import powerful from '../../images/powerful.svg'
    import load from '../../images/load.svg'
    import payments from '../../images/payments.svg'

const InfoTwo = ({lightBg,id,img,imgStart,topline,lightText,darkText,
headline,description,buttonlabel, alt,des1,des2, des3,des4, primary, dark, dark2}) => {
    return (
        <>
        <InfoTwoContainer lightBg = {lightBg} id = "why">
            <InfoTwoWrapper>
                <InfoTwoRow imgStart = {imgStart}>
                    <Column1>
                    <TextWrapper>
                        <TopLine>{topline}</TopLine>
                        <Heading lightText={lightText}>{headline}</Heading>
                        <Subtitle darkText={darkText}>{description}</Subtitle>
                        {/* <BtnWrap>
                            <Button to = 'home'
                            smooth= {true}
                            duration= {500}
                            spy= {true} 
                            exact = "true"
                            offset = {80}
                            primary={primary ? 1 : 0}
                            dark = {dark ? 1: 0}
                            dark2 = {dark2 ? 1: 0}
                            
                            >{buttonlabel}</Button>
                        </BtnWrap> */}
                        <Button href = "https://www.youtube.com/watch?v=fRDGgqIfbYs" variant="success">Learn more</Button>
                    </TextWrapper>
                    </Column1>
                    <Column2>
                    <ImgWrap>
                    <Img  src= {greenenv} alt="100% Greener"/>
                 
                   
                   
                    <Img  src= {powerful} alt={'Almost 3 times durable'}/>
                    <Img  src= {load} alt={'2.5 X load bearing capacity'}/>
                    <Img  src= {payments} alt={'10% Cheaper'}/>
                    </ImgWrap>
                    </Column2>
                    <Column3> 
                    <InfoP>{des1}</InfoP>  
                    <InfoP>{des2}</InfoP> 
                    <InfoP>{des3}</InfoP> 
                    <InfoP>{des4}</InfoP> 
                    </Column3>
                </InfoTwoRow>
            </InfoTwoWrapper>
        </InfoTwoContainer>
        </>
    )
}

export default InfoTwo

















