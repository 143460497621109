import React from 'react'
import {MissionContainer, MissionWrapper,MissionRow, Column1,Column2,
     TextWrapper, Heading, Subtitle,Img,MissionH1, ImgWrap} from './missionElements';
import vision from '../../../images/vision.svg'
import { BCard } from '../../blogs/BlogElements';
import Values from '../../blogs/OurValues';
import Content from '../intro/content';

const Mission = () => {
    return (
        <>
       <MissionContainer>
       <MissionH1>ABOUT US</MissionH1>
           <MissionWrapper>
            
           
               <MissionRow>   
                   <Column1>
                   <TextWrapper>
                       <Heading> Vision</Heading>
                       <Subtitle>To make Nepal a plastic waste-free country</Subtitle>
                       <Heading>Our Mission</Heading>
                       <Subtitle>Develop, deploy & transfer technologies for solving recyclable & non-recyclable plastic waste problems</Subtitle>
                       <Heading>What we do!</Heading>
                       <Subtitle>We simply change the direction of plastic waste
                         (Lanfill to Roadfill)</Subtitle>
                   </TextWrapper>
                   </Column1>
                   <Column2>
                   <TextWrapper>
                       <ImgWrap>
                       <Img src = {vision}>
                       </Img>
                       </ImgWrap>
                       
                   </TextWrapper>
                   </Column2>
               </MissionRow>
              
           </MissionWrapper>
           <Values />
           <br />
           <br />
               <Content />
               
       </MissionContainer>
       </>
    )
}

export default Mission
