import React from 'react';
import Icon1 from '../../images/road.svg'
import Icon2 from '../../images/greenbricks.svg'
import Icon3 from '../../images/curriculum.svg'
import Icon4 from '../../images/recovermethod.svg'
import {ServicesContainer, 
    ServicesH1, ServicesWrapper, ServicesCard,
     ServicesIcon, ServicesH2, ServicesP } from './ServicesElements';
     import {Button} from 'react-bootstrap'
    import {Link} from 'react-router-dom';

const Services = () => {
    return (
        <ServicesContainer id = "products">
            <ServicesH1>OUR PRODUCTS AND SERVICES</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>GREEN ROAD </ServicesH2>
                    <ServicesP>It improves spot use of the waste plastic.</ServicesP>
                   
                    <Button href = "/facts/greenroad" size="sm" variant="success">Read more</Button>
                    
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>GREEN BRICKS </ServicesH2>
                    <ServicesP> You have an option for greener construction.</ServicesP>
                    
                    <Button  href = "/facts/greenbricks" size="sm" variant="success">Read more</Button>
                  

                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>CURRICULUM </ServicesH2>
                    <ServicesP>Helps in sustainable learning from school level.</ServicesP>
                    
                    <Button href = "/facts/greencurriculum"  variant="success" size="sm" color ="#00a651">Read more</Button>
                  
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon4} />
                    <ServicesH2>WASTE AUDITING </ServicesH2>
                    <ServicesP>Focuses on resource recovery methods.</ServicesP>
                    {/* <Link to ="/facts/wasteauditing"> */}
                    <Button  href = "facts/wasteauditing" variant="success" size="sm" >Read more</Button>
                    {/* </Link> */}
                </ServicesCard>

            </ServicesWrapper>

        </ServicesContainer>
    )
}

export default Services
