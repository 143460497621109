import styled from 'styled-components'
export const BCard = styled.div
`
margin-top: 50px;
background: #fafafa;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 10px;
max-height: 3000px;
max-width: 900px;
padding: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;

&: hover {
    transform: scaled(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

`
export const BWrapper = styled.div
`
max-width: 1000px;
 margin:  auto;
display: grid;
grid-template-columns: 1fr;
align-items: center;
grid-gap:20px;
padding: 0 50px;
 



@media screen and (max-width: 1000px){
    grid-template-columns: 1fr ;
}

@media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
}
@media screen and (max-width: 480px){
   grid-template-columns: 1fr;
}
@media screen and (max-width: 320px){
    grid-template-columns: 1fr;
 }
}
`

export const CalContainer= styled.div
`
color: #000000;
background: ${({ lightBg }) => (lightBg ? '#EBECF0': '#fafafa')};
padding-top: 10px;
// @media screen and (max-width: 768px){
//     padding:-40px 0;
}

`
export const CalWrapper = styled.div
`
display: grid;
z-index: 1;
height: 600px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;
justify-content: center;

@media screen and (max-width: 768px){
    height: 700px;
}
@media screen and (max-width: 480px){
    height: 750px;
}
`
export const CalRow = styled.div
`
display: grid;
grid-auto-columns: minmax(auto, 2.5fr);
align-items: flex-start;
 grid-template-areas: ${({imgStart}) => (imgStart ? `'col1 col2'`: `'col1 col2'`)};

@media screen and (max-width: 768px){
    grid-template-areas: ${({imgStart}) =>(imgStart ? `'col1''col2'` : `'col1 col2'`)};
}
`
export const Column1 = styled.div
`
max-width:500px;
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
`
 export const Column2 = styled.div
 `
 max-width: 500px;
 padding-top: 0 15px;
 grid-area: col2;
 `
export const TextWrapper = styled.div
`
max-width: 540px;
padding-top: 0px;
// padding-bottom: 60px;

@media screen and (max-width: 768px){
    padding-top:25px;
}
@media screen and (max-width: 480px){
   padding-top: 25px;
}
`
export const TopLine = styled.p
`
color: #01bf71;
font-size: 16px;
line-height: 0;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
//margin-bottom: 16px;
`
export const CalculateH1= styled.h1
`
font-size: 2.3rem;
color:#00B74A;
margin-top: 135px;
margin-bottom:30px;
font-weight: bold;
text-align: center;
@media screen and (max-width: 768px){
    font-size: 1.3rem;
   
}

@media screen and (max-width: 480px){
    font-size: 1.3rem;
    margin-top: 70px;
}
`
export const Heading = styled.h1
`
margin-top: 40px;
font-size: 28px;
// line-height: 1.1;
font-weight: 600;
margin-left:100px;
color: ${({lightText}) => (lightText ? '#00B74A': '#00B74A')};

@media screen and (max-width: 768px){
    font-size: 20px;
    margin-left:40px;

}
`
export const Subtitle= styled.p
`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({darkText}) => (darkText ? '#000000': "#000000")};
`
