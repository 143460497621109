import './App.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import Home from './pages';
import Navbar from './components/Navbar';
import blog from '../src/components/blogs/blog'
import team from '../src/components/about/teamPage';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import 'react-toastify/dist/ReactToastify.css'
function App() {
  return (
   <Router> 
        <MessengerCustomerChat
        pageId= "626975134343841"
        appId = "5509620762412358"
        themeColor = "#00B74A"
        />
     <Switch>
         <Route  exact path="/" component={Home} />
        <Route path = "/facts" component = {blog}/>
        <Route path = "/about" component = {team}/>
       
        <Home />
     </Switch>
    
    </Router>
    
  );
}

export default App;
