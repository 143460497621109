import React, {useState, Component} from 'react'
import { BlogContainer, BlogWrapper,BH2, BP, ContentA, Heading, ImgWrap,
         TextWrapper,Img, ContentC } from '../BlogElements'
import gcurriculum from '../../../images/gcurriculum.jpg';
import Image from 'react-bootstrap/Image';
import {Col, Button} from 'react-bootstrap';
import ToggleDisplay from 'react-toggle-display';


  export default class GreenCurriculum extends Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.state = { show: false };
    }
   
    handleClick() {
      this.setState({
        show: !this.state.show
      });
    }

    render(){
      const { show } = this.state;
      let title = this.state.show? "Show less": "Read more";
        return(
        <>
        <BH2>What can youngster learn from Green Curriculum?</BH2>
        
The problem of waste management is the current concern of the world. Waste 
management has become a national concern. In the context of our country, there is 
a necessity for proper waste management law. In addition to sufficient legislation, 
public awareness and involvement, good technical assistance, and adequate 
funding are crucial components of any waste management program.
<br />
<br />
The waste management curriculum is a pathway for the proper management of waste from the 
school level. It is important to spread the concept of reducing waste at the source 
and separating waste. Training is one way to promote the concept of waste reduction 
and environmental sustainability. School plays an important role in not only 
transforming knowledge but also in influencing actions. The waste management 
curriculum helps in increasing the level of awareness and knowledge and practices 
the management process of waste.
<br/>
<br />
        <ToggleDisplay if={this.state.show} tag="section">
        <Col xs={18} md={14} className="m-auto">
                <Image src= {gcurriculum} fluid />
                </Col>

<br />
<br />
The main objective of the waste management 
curriculum and green class is to raise awareness about waste and its effects on the 
environment and environmental sustainability. Providing waste management 
education in schools and colleges is a key strategy for dealing with the rising pressures 
of waste problems that come with rapid economic growth.
<br />
<br />
Green classes are a movement that focuses on reducing schools' ecological 
footprints, improving their environmental wellbeing, and pioneering sustainability in 
education. These types of curriculum foster leadership qualities as well as creative 
thinking and relationship skills. Green classes in a natural setting are suitable for social 
environmental education and environmental consciousness, as well as perceptions of 
agricultural production and its significance for social life, and strengthening the 
human-rate relationship. Green class prioritizes and maintains relationships among all 
students, the environment, and the community.. It is the most efficient means of 
ensuring constructive learning in a pleasant atmosphere while conserving energy and 
money. Green curriculum teaches the life lesson to students like minimizing the waste, 
turning off the switch, saving water, reusing the things, buying only what is important, 
planting trees, composting, roof-top gardening, using bio gas etc. 
<br />
<br />
In our context, waste out of the sight means out of mind. We collect the waste from 
our respective house and dump it together in landfill sites and consider it as proper 
disposal of waste; which means the lack of proper knowledge of waste disposal. In 
the waste management curriculum, we can first make a questionnaire about the 
waste to ask the students to know how many of them can identify and classify waste. 
We can start from basic to let them know about waste management.
We can ask some questions like:
<br />
<br />
1. What is the waste generated at school ?
<br />
2. How do schools manage the waste ?
<br />
3. Are you involved in waste management? if no, then why not?
<br />
4. Do these waste management techniques are sufficient for proper waste 
management?
<br />
5. Does this waste have impacts on living organisms and the environment?
<br />
6.How do you think litters need to be managed?
<br />
<br />
School waste management curriculum includes a variety of stakeholders, including 
school administration, staff, pupils, the canteen, and other outside parties. To create a well-executed plan, school administration and teachers should collaborate. 
Students and the canteen are the next most important stakeholders in the plan, as 
they generate the majority of the waste in school, both organic and inorganic. We 
can show them some documentaries about waste, its disposal, and the effects of 
unmanaged waste, climate change and its effect and conduct a program once a 
week to teach them about types of waste and their disposal practically. Waste is an 
unavoidable by-product of existence and society's development but waste 
management efficiency is critical to a country's growth and people's health. 
We can prepare a small animated course book where the positive side of waste is 
described and green studies, a course of global perspectives, life skills, alternative 
energy, and practices. We can teach them about basic lifestyle choices like 
sustainable fashion, second hand and borrowing habits, cycling and walking instead 
of using buses and cars. We contribute to waste or resource reduction any time we 
choose to use the blank side of a printed page and refuse to use a plastic bag. We 
can conduct some classroom activity like telling them to list the things they used 
categorize them and tabulate them into things that can be reused, recycled. 3Rs 
principle should be prioritized. They should learn about good practices. Good 
practices include producing less waste, reusing consumables, recycling waste, and 
recovering useful energy from waste. They should be well taught about carbon foot 
prints and the ways to minimize it practically. We can start a campaign of using 
electric transportations than other vehicles in schools. They aid in the conservation of 
precious natural resources and energy, as well as the reduction of environmental 
degradation caused by socio-economic growth management is inseparably 
connected to the concept of sustainable development. 
<br />
<br />
Meeting the Sustainable Development Goals is a major global challenge. Waste 
management is also necessary. Finding creative waste management solutions is 
difficult because waste breakdown necessitates special processes that take time, 
resources, and money. Environment, climate change, pollution, and waste 
management are inter-connected. They are the key sources for sustainable 
development.
<br />
<br />
There are some green schools like Kopila Valley School in Surkhet, where the school is 
built using rammed-earth technology with an environmentally sustainable philosophy 
that uses local materials, harnesses solar and sewage energy harvests rainwater, and 
reduces waste. Vajra Academy in Lalitpur where the school has sustainable 
infrastructures and a green curriculum. 
<br />
<br />
We need more waste management curriculum and green classes in Nepal where we 
can learn and teach about waste management and sustainability from a small age. 
A change in students’ behavior might give a significant impact to waste generation 
of school. Environmental education is not formally taught in many schools around the 
world. As a result, students have a limited understanding of the natural world.

</ToggleDisplay>
<Button onClick={ () => this.handleClick()} variant ="success" size = "sm">{title}</Button>
</>

        )}

  }
